import Head from "next/head";

import { Authenticated } from "src/components/Authenticated";
import ExtendedSidebarLayout from "src/layouts/ExtendedSidebarLayout";

import DashboardAnalyticsContent from "src/content/DashboardPages/analytics";

function DashboardAnalytics() {
  return (
    <>
      <Head>
        <title>Interplay POS</title>
      </Head>
      <DashboardAnalyticsContent/>
    </>
  );
}

DashboardAnalytics.getLayout = (page) => (
  <Authenticated>
    <ExtendedSidebarLayout>{page}</ExtendedSidebarLayout>
  </Authenticated>
);

export default DashboardAnalytics;
