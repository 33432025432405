import { Typography, Box, Avatar, Card, CardContent, styled, Button, Menu, MenuItem, useTheme } from '@mui/material';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { useContext, useEffect, useRef, useState } from 'react';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { AuthContext } from 'src/contexts/FirebaseAuthContext';
import useAgentStore from 'src/hooks/UseStore';
import DownloadReport from './DownloadReport';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.gradients.orange1};
    color:  ${theme.palette.primary.contrastText};
  `
);

function Sales() {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const [data, setData] = useState(0);
  const agentType = useAgentStore((state) => state.agentType);
  const [openPeriod, setOpenMenuPeriod] = useState(false);
  const [period, setPeriod] = useState('all'); 
  let id_usuario;

  if(agentType === 'Agente'){
    id_usuario = user?.id_agente;
  } else if(agentType === 'SubConcesionario'){
    id_usuario = user?.id_subconcesionario;
  } else if(agentType === 'Concesionario'){
    id_usuario = user?.id_concesionario;
  }

  useEffect(() => {
    fetch(`/api/sales?id_usuario=${id_usuario}&period=${period}`)
      .then((response) => response.json())
      .then((data) => {
        const sum = data.reduce((acc, curr) => acc + Number(curr.monto), 0);
        const roundedSum = Math.round(sum * 100) / 100;
        const formattedSum = roundedSum.toFixed(2);
        setData(data.length ? formattedSum : '0.00');

      })
      .catch((error) => {
        console.error("Error fetching sales count:", error);
      });
  }, [period]);

  const periods = [
    {
      value: 'today',
      text: 'Hoy'
    },
    {
      value: '7_days',
      text: 'Ultimos 7 dias'
    },
    {
      value: '30_days',
      text: 'Ultimos 30 dias'
    },
    {
      value: '90_days',
      text: 'Ultimos 90 dias'
    },
    {
      value: 'all',
      text: 'Todas'
    }
  ];

  const columns = [
    { header: "ID Transacción", key: "id_transaccion", width: 25 },
    { header: "Fecha", key: "fecha", width: 20 },
    { header: "Monto", key: "monto", width: 15 },
    { header: "Tipo", key: "tipo", width: 15 },
    { header: "ID Autor", key: "id_autor", width: 15 },
  ];
  const actionRef1 = useRef(null);

  return (
    <Card sx={{ background: `${theme.colors.gradients.black1}` }}>
      <CardContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
              <AvatarWrapper>
              <PointOfSaleIcon sx={{ color: 'lightgreen' }} />
              </AvatarWrapper>
              <Typography
                sx={{ ml: 1 }}
                variant="body1"
                color="white"
                component="div"
              >
                Ventas
              </Typography>
          </Box>
          <Box>
              <Button
                size="small"
                variant="outlined"
                ref={actionRef1}
                onClick={() => setOpenMenuPeriod(true)}
                endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
              >
                {periods.find(p => p.value === period)?.text}
              </Button>
              <Menu
                disableScrollLock
                anchorEl={actionRef1.current}
                onClose={() => setOpenMenuPeriod(false)}
                open={openPeriod}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {periods.map((_period) => (
                  <MenuItem
                    key={_period.value}
                    onClick={() => {
                      setPeriod(_period.value);
                      setOpenMenuPeriod(false);
                    }}
                  >
                    {_period.text}
                  </MenuItem>
                ))}
              </Menu>
          </Box>
      </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ py: 2 }}
        >
          <Typography variant="h2" color="white" sx={{ mr: 1 }}>
            L {data !== null ? data : 0}
          </Typography>
        </Box>
        <DownloadReport
          id={id_usuario}
          period={period}
          paramKey={"id_usuario"}
          apiEndpoint="/api/sales"
          worksheetName="Ventas"
          fileName="ventas"
          columns={columns}
        />
      </CardContent>
    </Card>
  );
}

export default Sales;