import { Typography,Box,Avatar,Card,CardContent,styled, useTheme} from '@mui/material';
import AccountBalanceTwoTone from '@mui/icons-material/AccountBalanceTwoTone';
import { useContext} from 'react';
import { AuthContext } from 'src/contexts/FirebaseAuthContext';
import useAgentStore from 'src/hooks/UseStore';
  
const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        background: ${theme.colors.gradients.orange1};
        color:  ${theme.palette.primary.contrastText};
  `
);
  
function Balance() {
  const theme = useTheme();
  const agentType = useAgentStore((state) => state.agentType);
  const { user } = useContext(AuthContext);
  let agentId = null;


  if(agentType === 'Agente'){
    agentId = user?.id_agente;
  } else if(agentType === 'SubConcesionario'){
    agentId = user?.id_subconcesionario;
  } else if(agentType === 'Concesionario'){
    agentId = user?.id_concesionario;
  }
    return (
      <Card
      sx={{
        background: `${theme.colors.gradients.black1}`,
      }}>
        <CardContent>
          <Box display="flex" alignItems="center">
            <AvatarWrapper>
            <AccountBalanceTwoTone sx={{ color: 'lightgreen' }} />
            </AvatarWrapper>
            <Typography
              sx={{
                ml: 1
              }}
              variant="body1"
              color="white"
              component="div"
            >
              Saldo
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              ml: -1.5,
              py: 2,
              justifyContent: 'center'
            }}
          >
            <Typography variant="h2" color="white">
            L {(Number(user?.saldo) || 0).toFixed(2)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }
  
  export default Balance;
  