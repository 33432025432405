import {Box,Card,Typography,Avatar,styled,useTheme,CardContent, Button, Menu, MenuItem,} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "src/contexts/FirebaseAuthContext";
import useAgentStore from "src/hooks/UseStore";
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.gradients.orange1};
        color:  ${theme.palette.primary.contrastText};
  `
);

function Affiliates() {
  const theme = useTheme();
  const [affiliates, setAffiliates] = useState(0);
  const {user} = useContext(AuthContext);
  const agentType = useAgentStore((state) => state.agentType);
  let id_usuario;
  const [period, setPeriod] = useState('all');
  const [openPeriod, setOpenMenuPeriod] = useState(false);
  
  const actionRef1 = useRef(null);

  if(agentType === 'Agente'){
    id_usuario = user?.id_agente;
  } else if(agentType === 'SubConcesionario'){
    id_usuario = user?.id_subconcesionario;
  } else if(agentType === 'Concesionario'){
    id_usuario = user?.id_concesionario;
  }
  useEffect(() => {
    fetch(`/api/affiliates?id_usuario=${id_usuario}&period=${period}`)
      .then((response) => response.json())
      .then((data) => {
        setAffiliates(data.affiliatesCount);
      })
      .catch((error) => {
        console.error("Error fetching affiliates count:", error);
      });
  }, [id_usuario, period]);
  
  const periods = [
    {
      value: 'today',
      text: 'Hoy'
    },
    {
      value: '7_days',
      text: 'Ultimos 7 dias'
    },
    {
      value: '30_days',
      text: 'Ultimos 30 dias'
    },
    {
      value: '90_days',
      text: 'Ultimos 90 dias'
    },
    {
      value: 'all',
      text: 'Todos'
    }
  ];

  return (
    <Card
      sx={{
        background: `${theme.colors.gradients.black1}`,
      }}
    >
      <CardContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <AvatarWrapper>
          <PersonAddIcon sx={{ color: 'lightgreen' }} />
          </AvatarWrapper>
          <Typography
            sx={{ ml: 1 }}
            variant="body1"
            color="white"
            component="div"
          >
            Afiliados
          </Typography>
        </Box>
        {/* <Box display="flex" alignItems="center">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              pr: 0.5,
              color: `${theme.colors.success.main}`,
              fontSize: '10px',  // Set font size to 10px
            }}
          >
            <ArrowUpwardTwoToneIcon
              fontSize="small"
              sx={{ mr: 0.2 }}
            />
            <span>0%</span>
          </Typography>
          <Typography
            noWrap
            sx={{
              color: `${theme.colors.alpha.trueWhite[70]}`,
              fontSize: '10px',  // Set font size to 10px
            }}
          >
            incremento este mes
          </Typography>
        </Box> */}
        <Button
            size="small"
            variant="outlined"
            ref={actionRef1}
            onClick={() => setOpenMenuPeriod(true)}
            endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
          >
            {periods.find(p => p.value === period)?.text}
          </Button>
          <Menu
            disableScrollLock
            anchorEl={actionRef1.current}
            onClose={() => setOpenMenuPeriod(false)}
            open={openPeriod}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {periods.map((_period) => (
              <MenuItem
                key={_period.value}
                onClick={() => {
                  setPeriod(_period.value);
                  setOpenMenuPeriod(false);
                }}
              >
                {_period.text}
              </MenuItem>
            ))}
          </Menu>
      </Box>

        <Box
          mt={1.5}
          pb={2}
          display="flex"
          alignItems="center"
          sx={{
            justifyContent: 'center'
          }}
        >
 
            <Typography
              variant="h2"
              sx={{
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              {affiliates !== null ? affiliates : 0}
            </Typography>
 
        </Box>
      </CardContent>
    </Card>
  );
}

export default Affiliates;
