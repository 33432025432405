import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Balance from "./Balance";
import BankFees from "./BankFees";
import Sales from "./Sales";
import Affiliates from "./Affiliates";

function DashboardAnalyticsContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ overflowY: isMobile ? 'auto' : '', marginBottom: isMobile ? '80px' : '0px' }}>
      <Grid
        sx={{ px: 4, mt: '30px'}}
        container
        direction="row"
        justifyContent="center"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={3}
        >
          <Grid item lg={4} sm={7} xs={12} md={6}>
            <Balance />
          </Grid>
          <Grid item lg={4} sm={7} xs={12} md={6}>
            <Affiliates />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          mt={3}
        >
          <Grid item lg={4} sm={7} xs={12} md={6}>
            <Sales />
          </Grid>
          <Grid item lg={4} sm={7} xs={12} md={6}>
            <BankFees />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardAnalyticsContent;
